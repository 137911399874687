<template>
  <v-row>
    <v-col cols="12">

      <v-sheet
        width="100%"
        outlined>
        <v-col cols="12" class="pt-8 pl-8 pr-8" v-if="!loading">
          <label>
            MÓDULO:
          </label>
          <p class="text-justify text--secondary">
            {{ module_incubation.name }}
          </p>

          <label>
            DESCRIPCIÓN:
          </label>
          <p class="text-justify text--secondary">
            {{ module_incubation.description }}
          </p>
        </v-col>

        <v-col cols="12" class="pl-8 pr-8" v-if="!loading">
          <v-sheet
            outlined
            class="pa-4">
            
            <label>
              CATEGORIAS:
            </label>
            <v-row>
              <v-col cols="4" v-for="category in entrepreneurship_categories" v-bind:key="category.id">
                <v-switch
                  :label="category.name"
                  v-model="category.enabled"
                  @click="handdleAssignedModulesCategory(category.id, category.enabled)"
                ></v-switch>
              </v-col>
            </v-row>

          </v-sheet>
        </v-col>

        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" width="10%">
                      #ID
                    </th>
                    <th class="text-center" width="70%">
                      Emprendimiento
                    </th>
                    <th class="text-center" width="20%">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in entrepreneurships"
                    :key="item.id"
                  >
                    <td class="text-center">{{ item.id }}</td>
                    <td>
                      {{ item.title }}
                      <br>
                      <template
                        v-if="item.categories.length>0">
                        <v-chip
                          v-for="category in item.categories"
                          :key="category.id"
                          class="mr-2"
                          x-small
                          color="red darken-1"
                          text-color="white"
                        >
                          {{ category.name }}
                        </v-chip>
                      </template>
                    </td>
                    <td class="text-center d-flex justify-center">
                      <v-switch
                        v-model="item.assigned"
                        @click="handdleAssignedModules(item.id, item.assigned)"
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="pt-8 d-flex justify-space-between">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'modulesIncubation'}"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>
    </v-col>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <label>Cargando...</label>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading_module"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <label>Cargando...</label>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </v-row>
</template>
<script>
  import axios from 'axios'

  export default {
    name: 'modulesAssignedToEntrepreneurships',

    data: () => ({
      loading:true,
      loading_module:false,
      show:false,
      switch: false,
      edition_id:null,
      module_id: null,

      entrepreneurships:[],
      module_incubation: [],
      entrepreneurship_categories: []
    }),

    methods:{
      async getIncuvationModules(){
        await axios.get('admin/edition/'+this.edition_id+'/modules-incubation/'+this.module_id+'/modules-assigned-entrepreneurships').then(response =>{
          this.entrepreneurships = response.data.data.entrepreneurships
          this.module_incubation = response.data.data.module_incubation
          this.entrepreneurship_categories = response.data.data.entrepreneurship_categories
          this.entrepreneurships.switch=false
          this.loading=false
          this.loading_module=false
          this.show=true
        }).catch(error => {
          console.log(error);
        })
      },

      async handdleAssignedModules(id, assigned){
        this.loading_module=true
        await axios.put('admin/edition/'+this.edition_id+'/modules-incubation/'+this.module_id+'/assigned-module',{
          'action': (assigned)?'add':'remove',
          'entrepreneurship_id': id,
          'module_id': this.module_id
        }).then( () =>{
          this.getIncuvationModules()
        }).catch(error => {
          console.log(error);
        })
      },

      async handdleAssignedModulesCategory(category_id, eneabled){
        this.loading_module=true
        await axios.put('admin/edition/'+this.edition_id+'/modules-incubation/'+this.module_id+'/handdle-categogy',{
          'action': (eneabled)?'add':'remove',
          'category_id': category_id,
          'module_id': this.module_id
        }).then( () =>{
          this.getIncuvationModules()
        }).catch(error => {
          console.log(error);
        })
      }
    },
    created(){
      this.edition_id = this.$route.params.edition_id
      this.module_id = this.$route.params.module_id
      this.getIncuvationModules()
    },
  }
</script>